<template lang="pug">
.homepage
  Start
  Rating
  Manage
  Distribute
  Analyze
  Monetize
  SwitchToRss
  Reviews
</template>

<script>
import getMeta from '@/lib/meta'
import { config } from '@/config'

import Start from '@/components/pages/landing/start'
import Rating from '@/components/pages/landing/rating'
import Manage from '@/components/pages/landing/manage'
import Distribute from '@/components/pages/landing/distribute'
import Analyze from '@/components/pages/landing/analyze'
import Monetize from '@/components/pages/landing/monetize'
import SwitchToRss from '@/components/pages/landing/switch-to-rss'
import Reviews from '@/components/pages/landing/reviews'

export default {
  components: {
    Start,
    Rating,
    Manage,
    Distribute,
    Analyze,
    Monetize,
    SwitchToRss,
    Reviews
  },

  layout: 'landing',

  middleware: [
    'languageRedirect'
  ],

  head () {
    const resultMeta = getMeta({
      title: this.$t('meta.homepage.title'),
      metaTitle: this.$t('meta.homepage.metaTitle'),
      description: this.$t('meta.homepage.description'),
      route: this.$route,
      i18nLocales: this.$i18n.locales,
      i18nSeo: this.$nuxtI18nSeo(),
      options: {
        gdpr: this.$store.state.auth.gdprThirdParty
      }
    })

    // json+ld data
    const jsonLdOrganisationInfo = {
      '@type': 'Organization',
      '@context': 'https://schema.org',
      foundingDate: '2018',
      name: 'RSS.com',
      alternateName: 'RSS․com',
      url: config.baseUrl,
      logo: config.baseUrl + '/icon@2x.png',
      sameAs: [
        'https://twitter.com/rss',
        'https://www.facebook.com/rss.podcasting',
        'https://www.linkedin.com/company/rsscom',
        'https://www.instagram.com/rss_podcasting'
      ]
    }

    resultMeta.script.push({
      type: 'application/ld+json',
      innerHTML: JSON.stringify(jsonLdOrganisationInfo)
    })

    return resultMeta
  },

  mounted () {
    const hash = this.$route.hash

    if (hash) {
      location.hash = ''
      location.hash = hash
    }
  }
}
</script>

<style lang="scss">
@import "./../components/pages/landing/index.scss"
</style>

<style lang="scss" scoped>
.homepage {
  background: #1C0C32;
}
</style>
